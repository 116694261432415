#contact {
  // border: 1px solid red;
  margin-top: 60px;


  .contact {
    .contact-container {
      // border: 1px solid red;
      padding: 0 24px;
      max-width: var(--max-width);
      margin: 0 auto;
      .contact-title {
        font-size: 40px;
        font-weight: 700;
        background-image: var(--gradient-45-deg);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
        line-height: 40px;

        @media screen and (max-width: 767px) {
          line-height: 40px;
        }
      }
      .contact-subtitle {
        display: block;
        color: var(--sub-color);
        font-size: 20px;
        line-height: 20px;
        // margin-top: 10px;

        @media screen and (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      .proposal-container {
        // border: 1px solid blue;
        margin-top: 30px;
        // margin-bottom: 60px;
        display: flex;
        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
          gap: 20px;
        }
      }

      .map-container {
        // border: 1px solid red;
        margin-top: 60px;

        .actual-map-container {
          // border: 1px solid blue;
          margin-top: 30px;
          height: 300px;
        }
      }

      .contact-links {
        margin-top: 60px;
        margin-bottom: 60px;
        // border: 1px solid red;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        gap: 50px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          gap: 20px;
        }

        a {
          text-decoration: none;
        }

        .each-contact-link {
        // border: 1px solid blue;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 767px) {
          justify-content: center;
        }

        span {
          color: var(--sub-color);
          font-size: 20px;
        }

          .call {
            background: #484785;
          }
          .email {
            background: #416893;
          }
          .fb {
            background: #42809E;
          }
          .icon-logo {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
        }
      }

      .options {
        // border: 1px solid green;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        // height: 500px;

        .each-options {
          height: 100%;
          min-height: 177.84px;
          @media screen and (max-width: 767px) {
            min-height: 100px;
            height: 100px !important;
          }
        }
      }
      .label {
        // border: 1px solid white;
        width: 80%;
      }
      .each-form {
        // border: 1px solid red;
        height: 100%;
      }
      .forms {
        // border: 1px solid green;
        width: 100%;

      }
      
    }
  }
}

.prop-body {
  // border: 1px solid green;

  form {
    // margin-top: 20px;
    .two-cols {
      // border: 1px solid blue;
      display: flex;
      gap: 10px;
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      .each-input {
        width: 100%;
      }
      .blank {
        width: 100%;
      }
      .send-form {
        width: 100%;
        height: 100%;
        background: transparent;
        border: 1px solid var(--main-color);
        font-family: "Afacad", sans-serif;
        color: var(--main-color);
        padding: 5px 0;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        cursor: pointer;
        box-sizing: border-box;
        // transition: all 300ms;
        &:hover {
          background-color: var(--main-color);
          color: white;
          .arrow-right {
            fill: white;
          }
        }
      }
      
    }
    .message {
      width: 100%;
      margin-bottom: 10px;

      .each-input {
        width: 100%;
      }
    }
    .alert {
      // border: 1px solid red;
      margin-bottom: 10px;
    }
  }

}

.form-placeholder {
  // border: 1px solid red;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  span {
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    color: #8c8c8c;
    text-align: center;
  }
}