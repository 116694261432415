#careers {
  // border: 1px solid red;
  margin-top: 60px;


  .careers {
    .careers-container {
      // border: 1px solid red;
      padding: 0 24px;
      max-width: var(--max-width);
      margin: 0 auto;
      .careers-title {
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
        background-image: var(--gradient-45-deg);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;

        @media screen and (max-width: 767px) {
          line-height: 40px;
        }
      }
      .careers-subtitle {
        display: block;
        color: var(--sub-color);
        font-size: 20px;
        line-height: 20px;
        // margin-top: 10px;

        @media screen and (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      .qq {
        font-size: 100px;
        color: var(--sub-color);

        @media screen and (max-width: 767px) {
          font-size: 80px;
          line-height: 80px;
        }
      }

      .why-container {
        // border: 1px solid blue;
        margin-top: 20px;
        margin-bottom: 60px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .why1 {
          background: #474684;
        }
        .why2 {
          background: #3E7096;
        }
        .why3 {
          background: #5193A9;
        }

        .each-why-card {
          // border: 1px solid green;
          // height: 250px;
          // width: 391px;
          flex: 1 1 calc(33.333% - 20px);

          /* Mobile View */
          @media screen and (max-width: 767px) {
            flex: 1 1 100%; /* 1 column */
          }

          .ret-img {
            // border: 1px solid red;
            height: 200px;
            // width: 300px;
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              position: absolute;
              object-fit: cover;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
            img:nth-child(1) {
              animation: fadeOut 10s infinite;
            }
            img:nth-child(2) {
              animation: fadeIn 10s infinite;
            }
            /* Keyframes for fading out Image 1 */
            @keyframes fadeOut {
              0% { opacity: 1; }
              45% { opacity: 1; }
              50% { opacity: 0; }
              100% { opacity: 0; }
            }

            /* Keyframes for fading in Image 2 */
            @keyframes fadeIn {
              0% { opacity: 0; }
              45% { opacity: 0; }
              50% { opacity: 1; }
              100% { opacity: 1; }
            }
          }
          .ret-title {
            display: block;
            margin: 10px 10px 5px 0;
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
            color: white;
            margin-left: 20px;

          }
          .ret-desc {
            margin-left: 20px;
            margin-right: 20px;
            display: block;
            color: white;
            margin-bottom: 20px;

          }
        }
      }

      .position-container {
        // border: 1px solid blue;
        margin-top: 30px;
        margin-bottom: 60px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .each-position-card {
          // border: 1px solid green;
          // height: 250px;
          // width: 391px;
          background: rgb(245, 245, 245) !important;
          flex: 1 1 calc(33.333% - 20px);

          /* Mobile View */
          @media screen and (max-width: 767px) {
            flex: 1 1 100%; /* 1 column */
          }

          .inner-position {
            // border: 1px solid red;
            padding: 20px;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .ret-title {
            display: block;
            // margin: 10px 0 5px 0;
            font-size: 20px;
            font-weight: 600;
            color: var(--sub-color);
            // margin-left: 20px;
            // margin-bottom: 30px;
          }
          .ret-desc {
            margin-left: 20px;
            margin-right: 20px;
            display: block;
            color: white;
            margin-bottom: 20px;

          }

          .about-button {
            // border: 1px solid red;
            // margin: 10px;
            margin-top: 20px;
            a {
              text-decoration: none;
            }
            width: 100%;
            .button {
              width: 100%;
              height: 100%;
              background: transparent;
              border: 1px solid var(--main-color);
              font-family: "Afacad", sans-serif;
              color: var(--main-color);
              padding: 5px 15px;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              // margin-top: 20px;
              // transition: all 300ms;
            }
            .arrow-right {
              fill: var(--main-color);
              // transition: all 300ms;
            }
  
            :hover {
              background-color: var(--main-color);
              color: white;
              .arrow-right {
                fill: white;
              }
            }
          }
        }
      }
      
    }
  }
}