#services {
  
  .type-container {
    margin-top: 30px; 
    display: flex;
    flex-direction: column;
    .type {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
}