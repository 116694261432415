#fit {

  .fit {
    // border: 1px solid red;
    padding: 0 24px;
    max-width: var(--max-width);
    margin: 0 auto;

    .article-top-margin {
      height: 90px;
    }

    .fit-article {
      // border: 1px solid blue;
      width: 50%;
      margin: 0 auto;
      margin-bottom: 60px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .back-button {
        // border: 1px solid red;
        width: 100px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px) {
          // margin-bottom: 30px !important;
        }


        .button {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          font-family: "Afacad", sans-serif;
          color: var(--main-color);
          padding: 0;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
          cursor: pointer;
          // transition: all 300ms;

          .back-arrow {
            transform: rotate(180deg);
          }
        }
        .arrow-right {
          fill: var(--main-color);
          // transform: rotate(180deg);
          // transition: all 300ms;

        }

        // :hover {
        //   // background-color: var(--main-color);
        //   color: var(--sub-color);
        //   .arrow-right {
        //     fill: var(--sub-color);
        //   }
        // }
      }


      .fit-article-banner-container {
        // border: 1px solid green;
        // overflow: hidden;
        // height: 200px;
        // height: 100%;
        position: relative;
        margin-bottom: 20px;
        // margin-top: 20px;

        @media screen and (max-width: 767px) {
          margin-top: 30px;
        }

        img {
          width: 100%;
          // height: 100%;
          // width: 100%;
          // object-fit: cover;
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // -ms-transform: translate(-50%, -50%);
          // transform: translate(-50%, -50%);
        }

      }


      .reader-article-headline {
        color: var(--sub-color);
        font-size: 30px;
        line-height: 30px;
        font-weight: 700;
      }

      .article-author {
        display: block;
        color: var(--subtitle);
        // margin-top: 30px;
      }

      .article-body {
        // border: 1px solid red;
        // margin-top: 30px;

        .article-body-row {
        // border: 1px solid blue;
        display: flex;
        align-items: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        .article-body-image-container {
          // border: 1px solid green;
          // object-fit: cover;
          // width: 100%;
          // height: 100%;
          // width: 500px;
          // flex: 100%;
          img {
            // width: 100%;
            // height: 100%;
            // aspect-ratio: auto;
          }

        }
        img {
          // border: 1px solid green;
          height: 300px;
          aspect-ratio: auto;
          width: 100%;
          @media screen and (max-width: 767px) {
            height: auto;
          }
        }
        .article-body-p-container-right {
          // border: 1px solid green;

          padding: 20px 0 20px 20px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            padding: 0;
          }
        }
        .article-body-p-container-left {
          padding: 20px 20px 20px 0;
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            padding: 0;
            // padding: 0 0 20px 0;
            // margin-top: 20px;
          }
        }

        }
        .article-body-col {
        // border: 1px solid blue;
        display: flex;
        align-items: row;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        .article-body-image-container {
          // border: 1px solid green;
          // object-fit: cover;
          // width: 100%;
          // height: 100%;
          // width: 500px;
          // flex: 100%;
          img {
            // width: 100%;
            // height: 100%;
            // aspect-ratio: auto;
          }

        }
        img {
          // border: 1px solid green;
          // height: 300px;
          aspect-ratio: auto;
          width: 100%;
          @media screen and (max-width: 767px) {
            height: auto;
          }
        }
        .article-body-p-container-right {
          // border: 1px solid green;

          padding: 20px 0 20px 20px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            padding: 20px 0;
          }
        }
        .article-body-p-container-left {
          // padding: 20px 20px 20px 0;
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            padding: 0 0 20px 0;
          }
        }

        }
      }


    }
  }
}