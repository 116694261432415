nav {
    background-color: var(--main-bg);
    // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
    transition: all 300ms linear;
    color: white;
    display: flex;
    width: 100%;
    height: 55px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    // Styles for mobile devices
    @media screen and (max-width: 767px) {
      z-index: 100 !important;
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: var(--max-width);
        padding: 0 24px;
        margin: 0 auto;
        border: var(--test2);

        ul {
            margin: 0;
        }
    
        .nav-links {
            display: flex;
            flex-direction: row;
            list-style: none;
    
            .link-name {
                background-color: var(--main-bg);
                color: var(--main-color);
                border: none;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                cursor: pointer;
                // font-family: "Roboto";
                font-size: 20px;
                font-weight: 700;
                border-radius: 20px;
                padding: 10px 20px;
                // margin: 0 10px 0 10px;
                transition: all 300ms linear;
                // box-shadow: -1px -2px 1px rgba(255, 255, 255, 1),
                //             1px 1px 3px rgba(0, 0, 0, 0.15);
            }
            .link-name:active {
                transition: all 300ms linear;
                // box-shadow: inset -1px -2px 1px rgba(255, 255, 255, 1),
                //             inset 1px 1px 3px rgba(0, 0, 0, 0.15);
            }
            .active-route {
                transition: all 300ms linear;
                // box-shadow: inset -1px -2px 1px rgba(255, 255, 255, 1),
                //             inset 1px 1px 3px rgba(0, 0, 0, 0.15);
                
                li {
                    display: inline-block;
                    transform: scale(0.98);
                }
            }
            .link-name:active li {
                display: inline-block;
                transform: scale(0.98);
                
            }
        }
        
        .nav-left {
            display: flex;
            flex-direction: row;
            align-items: center;

            a {
              .menu-link {
                text-decoration: none !important;
                color: red;
              }
            }
            

            @media screen and (max-width: 768px) {
                .main-links {
                  display: none;
                }
            }

            .logo {
              
              img {
                height: 35px;
                margin-top: 5px;
                cursor: pointer;

                @media screen and (max-width: 480px) {
                  // margin-left: 15px;
                }
              }
            }
        }

        .nav-rightMobile {
          display: flex;
          flex-direction: row;
        }

        .nav-right {
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 768px) {
                .main-link-contact {
                  display: none;
                }
            }

            .mode {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 0 0 0 10px;
                transition: all 300ms linear;
    
                path {
                    fill: var(--main-color);
                }
            }
            .neu {
                transition: all 300ms linear;
                // box-shadow: -1px -2px 1px rgba(255, 255, 255, 1),
                //             1px 1px 3px rgba(0, 0, 0, 0.15);
              }
            .mode:active {
                transition: all 300ms linear;
                // box-shadow: inset -1px -2px 1px rgba(255, 255, 255, 1),
                //             inset 1px 1px 3px rgba(0, 0, 0, 0.15);
            }
            .mode:active svg{
                display: inline-block;
                transform: scale(0.95);
            }
        }

        .menu {
            display: none;
            .mode {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 0 0 0 20px;
                transition: all 300ms linear;
    
                path {
                    fill: var(--main-color);
                }
            }
            .neu {
                transition: all 300ms linear;
                // box-shadow: -1px -2px 1px rgba(255, 255, 255, 1),
                //             1px 1px 3px rgba(0, 0, 0, 0.15);
              }
            .mode:active {
                transition: all 300ms linear;
                // box-shadow: inset -1px -2px 1px rgba(255, 255, 255, 1),
                //             inset 1px 1px 3px rgba(0, 0, 0, 0.15);
            }
            .mode:active svg{
                display: inline-block;
                transform: scale(0.95);
            }
        }
        @media screen and (max-width: 768px) {
            .menu {
              display: block;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .wrapper {
          // flex-direction: row-reverse;
          // padding: 0 10px;
        }
    }
}

.backdrop {
    min-height: 100vh;
    transition: all 300ms linear;
    position: relative;
    // background-color: green;
    
    .menu-open {
        height: 100vh;
        // background-color: red;
        display: none;
        position: fixed;
        width: 100%;
        display: flex;
        align-items: center;
        transition: all 300ms linear;
        // padding: 30px;

        .menu-panel {
            // height: calc(100vh - 140px);
            height: 100vh;
            width: 100%;
            border: var(--test2);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 140px;
            justify-content: flex-start;
            color: var(--main-color);

            path {
                fill: var(--main-color);
            }

            .menu-top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: 768px;
                margin: 0 auto;
                margin-top: 20px;
                border: var(--test2);
                // border: 1px solid red;

                .menu-top-link {
                    border: var(--test1);
                    width: 100%;
                    padding: 0 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    

                    .menu-top-in {
                        border: var(--test4);
                        padding: 10px 0;
                        border-radius: 20px;
                        transition: all 300ms linear;

                        .menu-nav-link {
                            text-decoration: none;
                            color: var(--main-color);
                        }
                        .each-menu {
                            width: 90%;
                            border: var(--test3);
                            display: flex;
                            justify-content: space-between;
                            padding: 10px 0;
                            margin: 0 auto;
                            span {
                              font-weight: 700;
                            }
                            
                        }
                        hr {
                            // border: 1px solid rgba(255, 255, 255, 0.08);
                            width: 90%;
                        }
                    }
                    .menu-top-in:active {
                        transition: all 300ms linear;
                        // box-shadow: inset -1px -2px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 3px rgba(0, 0, 0, 0.50) !important;
                    }
                    .menu-bot-in {
                        border: var(--test4);
                        padding: 0;
                        border-radius: 30px;
                        margin-top: 20px;
                        transition: all 300ms linear;

                        .menu-nav-link {
                            text-decoration: none;
                            color: var(--main-color);
                        }
                        .each-menu {
                            width: 90%;
                            border: var(--test3);
                            display: flex;
                            justify-content: space-between;
                            padding: 15px 0;
                            margin: 0 auto;
                        }
                        hr {
                            // border: 1px solid rgba(255, 255, 255, 0.08);
                            width: 90%;
                        }
                    }
                    .menu-bot-in:active {
                        transition: all 300ms linear;
                        // box-shadow: inset -1px -2px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 3px rgba(0, 0, 0, 0.50) !important;
                    }

                    
                }
            }
            
        }
    
    }
    .menu-closed {
        // display: none;
        height: 0;
    }
    
    .open{
        display: block;
    }
    .closed{
        display: none;
    }
    
    .footer {
        // background-color: #313131 !important;
        transition: all 300ms linear;
        // height: 200px;
        // position: absolute;
        // bottom: -240px;
        // width: 100%;

        .wrapper {
          display: flex;
          flex-direction: column;
        }

        .footer-contact {
          // border: 1px solid blue;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 60px;
          
          .footer-contact-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          span {
            text-align: center;
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 20px;
            background-image: var(--gradient-45-deg);
            background-size: 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-text-fill-color: transparent;

            @media screen and (max-width: 767px) {
              font-size: 30px;
            }
          }

          .talk {
            width: 200px;
            // height: 100%;
            background: transparent;
            border: 1px solid var(--main-color);
            font-family: "Afacad", sans-serif;
            color: var(--main-color);
            padding: 5px 0;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            cursor: pointer;
            box-sizing: border-box;
            // transition: all 300ms;
            &:hover {
              background-color: var(--main-color);
              color: white;
              .arrow-right {
                fill: white;
              }
            }
          }
          
        }

        .footer-hr {
          border-top: 0.5px solid rgba(139, 139, 139, 0.4);
          margin-bottom: 60px;
        }

        .footer-holder {
          // border: 1px solid red;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .footer-left {
          // border: 1px solid red;

          .copyright {
            img {
              height: 35px;
              margin-bottom: 4px;
            }
            svg {
              height: 20px;
              margin-bottom: 4px;
              path {
                transition: all 300ms linear;
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              span {
                margin: 4px 0;
                transition: all 300ms linear;
                a {
                  text-decoration: none;
                  // color: #b3b3b3;
                  // transition: all 300ms linear;
                }
                a:hover {
                  color: var(--main-color) !important;
                  // transition: all 300ms linear;
                }
              }
              .terms, .privacy {
                // color: var(--main-color);
                cursor: pointer;
              }
              
            }

          }
        }
        .footer-right {
          // border: 1px solid yellow;
          @media screen and (max-width: 480px) {
            display: none;
          }

          span {
            font-size: 14px;
            font-weight: 700;
            // color: var(--subtitle);
            // color: red;
          }
          .qr {
            display: flex;
            flex-direction: column;
            img {
              height: 80px;
              margin-top: 8px;
            }
          }
        }

        .footer-grid {
          @media screen and (max-width: 480px) {
            flex-direction: column-reverse;
          }
          // display: grid;
          // grid-template-columns: repeat(3, 1fr);
          // grid-gap: 20px;
          // width: 100%;
          display: flex;
          flex-direction: row;
          // color: var(--subtitle);
          font-size: 14px;

          > div {
            @media screen and (max-width: 480px) {
              margin-right: 0;
              margin-bottom: 30px;
            }
            // border: 1px solid green;
            margin-right: 100px;
          }

          .footer-title {
            font-weight: 700;
          }

          .site-map {
            display: flex;
            flex-direction: column;
            

            .links-only {
              display: flex;
              flex-direction: column;
              @media screen and (max-width: 480px) {
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 10px;
                column-gap: 8px;
              }
              .nav {
                text-decoration: none;
                margin: 0 10px 8px 0;
                @media screen and (max-width: 480px) {
                  margin: 10px 0 25px 0;
                  // margin-top: 10px;
                  // margin: 10px 0 8px 0;
                }
              }
            }

            span {
              margin-bottom: 8px;
            }
            .site-mapLink {
              transition: all 300ms linear;
              cursor: pointer;
              border-radius: 5px;

              &:hover {
                transition: all 300ms linear;
                color: var(--main-color);

                @media screen and (max-width: 480px) {
                  border: 1px solid var(--main-color);
                }
                
              }

              @media screen and (max-width: 480px) {
                // border: 1px solid red;
                // border-radius: 20px;
                padding: 10px 20px;
              }
            }
          }
          .soc-med {
            display: flex;
            flex-direction: column;

            .links-only {
              display: flex;
              flex-direction: column;
              @media screen and (max-width: 480px) {
                flex-direction: row;
                margin-top: 10px;
                column-gap: 8px;
              }
            }

            a {
              text-decoration: none;
              // color: #b3b3b3;
              transition: all 300ms linear;
              
            }
            a:hover {
              color: var(--main-color) !important;
              transition: all 300ms linear;
            }

            span {
              margin-bottom: 8px;
            }
            .soc-medLink {
              transition: all 300ms linear;
              cursor: pointer;
              border-radius: 5px;

              &:hover {
                transition: all 300ms linear;
                color: var(--main-color);

                @media screen and (max-width: 480px) {
                  border: 1px solid var(--main-color);
                }
              }

              @media screen and (max-width: 480px) {
                // border: 1px solid red;
                // border-radius: 20px;
                padding: 10px 20px;
              }
              
            }
          }
          
      }
    }
}

