#home {
  height: 100vh;
  position: relative;
  z-index: 200 !important;
  // border: 1px solid red;
  // overflow-y: scroll;
  // background-color: white;

  // Styles for mobile devices
  @media screen and (max-width: 767px) {
    // height: calc(481vh);
    // height: 100%;
    margin-top: 57px;
    min-height: 100vh;
    overflow: auto;
  }

  .parent {
    // border: 1px solid green;
    // background: white !important;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    height: 100%;
    margin: 0 10px;
    // margin: 20px;

    // Styles for mobile devices
    @media screen and (max-width: 767px) {
      grid-auto-rows: auto;            /* Rows adjust based on content */
      align-items: start;              /* Prevents items from stretching vertically */
    }

    div {
      // border: 1px solid red;
      width: 100%;
      // height: 100%;
      
    }
    .div5, .div6, .div7 {
      @media screen and (max-width: 767px) {
        height: 300px
      }
    }

    .widget {
      background: hsla(0, 0%, 85%, 0.2);
      
    }


    .mcg-logo {
      // border: 1px solid red;
      // height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;

      // Styles for mobile devices
      // @media screen and (max-width: 767px) {
      //   height: 100px;
      // }

      img {
        height: 80px;

        // Styles for mobile devices
        @media screen and (max-width: 767px) {
          height: 70px;
          // margin-top: 20px;
        }
      }
    }
    .banner {
      // border: 1px solid red;
      // height: 300px;
      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        height: 100px;
      }
      .inner-banner {
        // border: 1px solid red;
        color: white;
        width: 100%;
        height: calc(100% - 10px);
        margin-top: 10px;
        background: mediumaquamarine;
        overflow: hidden;
        position: relative;

        // Styles for mobile devices
        @media screen and (max-width: 767px) {
          height: 100%;
          margin-top: 0;
        }

        .image-slider {
          width: 100%;
          height: 100%;
        }
        
        .zoom-container {
          // width: 300px;
          height: 100%;
          overflow: hidden;
          position: relative;
          // transform: translateY(-60%);
        
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            animation: zoom-in-out 20s infinite ease-in-out;
          }
        }
        
        @keyframes zoom-in-out {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }

        .banner-overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: var(--gradient-bg);
          z-index: 10;
          opacity: 0.6;
          
        }

        // .banner-title {
        //   // border: 1px solid red;
        //   width: 100%;
        //   height: 100;
        //   position: absolute;
        //   text-align: center;
        //   top: 25%;
        //   left: 100%;
        //   transform: translateX(-50%, -50%);
        //   z-index: 10;
        //   font-weight: 600;
        //   font-size: 70px;
        //   line-height: 70px;
        //   white-space: nowrap;
        //   will-change: transform;
        //   animation: marquee 15s linear infinite;

        //   // Styles for mobile devices
        //   @media screen and (max-width: 767px) {
        //     font-size: 40px;
        //     line-height: 40px;
        //     font-weight: 700;
        //     animation: marqueemobile 15s linear infinite;
        //   }

        //   @keyframes marquee {
        //     from { transform: translateX(0); }
        //     to { transform: translateX(-300%); }
        //   }
        //   @keyframes marqueemobile {
        //     from { transform: translateX(0); }
        //     to { transform: translateX(-350%); }
        //   }
        // }

        .banner-title {
          // background: yellow;
          position: absolute;
          z-index: 20;
          width: 100%;
          height: 100%;
          top: 0;
          display: flex;
          align-items: center;
          // white-space: nowrap;
          overflow: hidden;
          
          span {
            font-size: 70px;
            font-weight: 600;
            white-space: nowrap;
            will-change: transform;
            animation: marquee 20s linear infinite;


            // Styles for mobile devices
            @media screen and (max-width: 767px) {
              font-size: 40px;
              line-height: 40px;
              font-weight: 700;
              animation: marqueemobile 15s linear infinite;
            }

            @keyframes marquee {
              from { transform: translateX(0); }
              to { transform: translateX(-100%); }
            }
            @keyframes marqueemobile {
              from { transform: translateX(0); }
              to { transform: translateX(-100%); }
            }
          
          }


        }
      }
    }
    .contact-us {
      box-sizing: border-box;
      padding-top: 10px;
      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        padding-top: 0;
      }
      .inner-contact {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;

        // div {
        //   border: 1px solid blue;
        // }

        .contact-title {
          span {
            font-size: 30px;
            font-weight: 700;
            background: var(--gradient-bg);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            line-height: 30px;
          }
        }
        .contact-icons {
          // border: 1px solid yellow;
          display: flex;
          align-items: start;
          justify-content: center;
          gap: 10px;
          flex-direction: column;
          width: 260px;

          a {
            text-decoration: none;
          }

          .each-contact-details {
            // border: 1px solid green;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .each-contact-icon {
            // background: green;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
          .each-contact-info {
            color: var(--sub-color);
            font-size: 20px;
            text-decoration: none;
          }
          .call {
            background: #484785;
          }
          .email {
            background: #416893;
          }
          .facebook {
            background: #42809E;
          }
          .linkedin {
            background: #579CAE;
          }
        }
        .contact-details {
          color: var(--sub-color);
        }
      }
    }
    .about-us {
      padding: 10px;
      box-sizing: border-box;

      .inner-about {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .about-title {
          font-size: 30px;
          line-height: 30px;
          font-weight: 700;
          background: var(--gradient-bg);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
        .about-album {
          display: flex;
          flex-direction: row;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            flex-direction: column;
          }
          .each-album {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            img {
              height: 60px;
              width: 60px;

              // Styles for mobile devices
              @media screen and (max-width: 767px) {
                height: 150px;
                width: 150px;
              }
            }
            span {
              // Styles for mobile devices
              @media screen and (max-width: 767px) {
                font-size: 20px;
              }
            }
            .full-name {
              color: var(--sub-color);
              font-weight: 700;
              margin-top: 10px;
            }
            .suffix {
              color: var(--sub-color);
            }
            .position {
              color: var(--main-color);
              text-align: center;

              // Styles for mobile devices
              @media screen and (max-width: 767px) {
                margin-bottom: 20px;
              }
            }
          }
        }
        .about-button {
          // border: 1px solid red;
          width: 100%;
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid var(--main-color);
            font-family: "Afacad", sans-serif;
            color: var(--main-color);
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: var(--main-color);
            // transition: all 300ms;
          }

          :hover {
            background-color: var(--main-color);
            color: white;
            .arrow-right {
              fill: white;
            }
          }
        }
      }
    }
    .lob-service {
      padding: 10px;
      box-sizing: border-box;
      // background: linear-gradient(to right, #493F81 , #435C8D);
      position: relative;

      .lob-img {
        // border: 1px solid red;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .lob-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(45deg, rgba(252, 70, 106, 0.75) , rgba(63, 94, 251, 0.75));
      }
      .lob-overlay-emp {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(45deg, rgba(158, 189, 19, 0.75) , rgba(0, 133, 82, 0.75));
      }
      .lob-overlay-insu {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(45deg, rgba(0, 208, 255, 0.75) , rgba(58, 71, 213, 0.75));
      }

      .inner-pension {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 2;
        box-sizing: border-box;
        padding: 10px;

        .pension-title {
          font-size: 1.5vw;
          line-height: 1.5vw;
          font-weight: 700;
          color: white;
          text-align: center;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
            font-size: 30px;
            line-height: 30px;
          }
        }

        .tag-holder {
          // border: 1px solid red;
          width: fit-content;
          background: white;
          border-radius: 3px;
          padding: 3px 0;
          text-align: center;
          @media screen and (max-width: 767px) {
            padding: 8px 0;
          }
        }
        .pension-tags {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          font-size: 2vh;
          line-height: 2vh;

          @media screen and (max-width: 767px) {
            font-size: 25px;
          }

          span {
            background-color: white;
            padding: 5px 8px;
            background: linear-gradient(45deg, rgb(252, 70, 106) , rgb(63, 94, 251));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .bene-tags {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          font-size: 2vh;
          line-height: 2vh;

          @media screen and (max-width: 767px) {
            font-size: 25px;
          }

          span {
            background-color: white;
            padding: 5px 8px;
            background: linear-gradient(45deg, rgb(158, 189, 19) , rgb(0, 133, 82));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .insu-tags {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          font-size: 2vh;
          line-height: 2vh;

          @media screen and (max-width: 767px) {
            font-size: 25px;
          }

          span {
            background-color: white;
            padding: 5px 8px;
            background: linear-gradient(45deg, rgb(0, 208, 255) , rgb(58, 71, 213));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .pension-button {
          width: 100%;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid white;
            font-family: "Afacad", sans-serif;
            color: white;
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: white;
            // transition: all 300ms;
          }

          :hover {
            background-color: white;
            color: #A551AD;
            .arrow-right {
              fill: #A551AD;
            }
          }
        }
        .bene-button {
          width: 100%;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid white;
            font-family: "Afacad", sans-serif;
            color: white;
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: white;
            // transition: all 300ms;
          }

          :hover {
            background-color: white;
            color: #52A231;
            .arrow-right {
              fill: #52A231;
            }
          }
        }
        .insu-button {
          width: 100%;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid white;
            font-family: "Afacad", sans-serif;
            color: white;
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: white;
            // transition: all 300ms;
          }

          :hover {
            background-color: white;
            color: #1C8EEA;
            .arrow-right {
              fill: #1C8EEA;
            }
          }
        }
      }
    }
    .news-pension {
      height: 100%;
      width: 100%;
      background: var(--sub-color);
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        height: 350px;
      }

      .top {
        .news-tag {
          border: 0.5px solid white;
          width: fit-content;
          background: var(--gradient-bg);
          color: white;
          border-radius: 3px;
          padding: 3px 8px;
        }
      }

      .center {
        // border: 1px solid red;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        .recom {
          height: 20px;
          margin-top: 10px;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            height: 30px;
          }
        }
        .rma-subtitle {
          display: block;
          color: white;
          font-size: 30px;
          line-height: 30px;
          font-weight: 700;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            font-size: 40px;
            line-height: 40px;
          }
        }
        .rma {
          // height: 25px;
          width: 100%;
          position: absolute;
          bottom: 5px;
          z-index: 1;
        }
        .romeo-blue {
          height: 70%;
          position: absolute;
          right: 0;
          bottom: 40px;
        }
      }

      .bot {
        .news-pension-button {
          // border: 1px solid red;
          width: 100%;
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid white;
            font-family: "Afacad", sans-serif;
            color: white;
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: white;
            // transition: all 300ms;
          }

          :hover {
            background-color: white;
            color: var(--sub-color);
            .arrow-right {
              fill: var(--sub-color);
            }
          }
        }
      }
    }
    .news-benefits {
      height: 100%;
      width: 100%;
      background: white;
      border: 1px solid rgba(154, 154, 154, 0.392);
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        height: 250px;
      }

      .top {
        .news-tag {
          border: 0.5px solid white;
          width: fit-content;
          background: var(--gradient-bg);
          color: white;
          border-radius: 3px;
          padding: 3px 8px;
        }
      }

      .center {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .iso {
          width: 100%;
        }
        
        .news-headline {
          color: black;
          font-size: 20px;
          text-align: center;
          a {
            text-decoration: none;
          }
          strong {
            color: var(--main-color) !important;
          }
        }
        .news-services {
          font-weight: 700;
          font-size: 20px;
        }
      }

      .bot {
        .news-pension-button {
          // border: 1px solid red;
          margin-top: 10px;
          width: 100%;
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid var(--main-color);
            font-family: "Afacad", sans-serif;
            color: var(--main-color);
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: var(--main-color);
            // transition: all 300ms;
          }

          :hover {
            background-color: var(--main-color);
            color: white;
            .arrow-right {
              fill: white;
            }
          }
        }
      }
    }
    .news-insurance {
      height: 100%;
      width: 100%;
      background: #8C1A33;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        // height: 250px;
      }

      .top {
        .news-tag {
          border: 0.5px solid white;
          width: fit-content;
          background: var(--gradient-bg);
          color: white;
          border-radius: 3px;
          padding: 3px 8px;
        }
      }

      .center {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .iso {
          width: 100%;
        }
        .upcoming {
          width: 100%;
          padding-bottom: 20px;
        }
        .news-services {
          color: white;
          font-size: 20px;
          font-weight: 700;
        }
        .upcoming {
          color: white;
          margin: 10px 0;
        }
        p {
          color: white;
        }
        ul {
          color: white;
        }
      }

      .bot {
        .news-pension-button {
          // border: 1px solid red;
          width: 100%;
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid white;
            font-family: "Afacad", sans-serif;
            color: white;
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: white;
            // transition: all 300ms;
          }

          :hover {
            background-color: white;
            color: #8C1A33;
            .arrow-right {
              fill: #8C1A33;
            }
          }
        }
      }
    }
    .quote-meet {
      box-sizing: border-box;
      padding: 10px;
      background: linear-gradient(45deg, #F42B03, #FFBE0B);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        height: 150px;
      }

      span {
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
        color: white;
      }

    }
    .careers {
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        height: 150px;
      }

      .careers-title {
        position: absolute;
        z-index: 1;
        top: 30px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 40px;
        font-weight: 700;
        text-shadow: 2px 3px 3px rgba(0,0,0,0.3);

      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: zoom-in-out 20s infinite ease-in-out;
      }

    }
    .youtube {
      // Styles for mobile devices
      @media screen and (max-width: 767px) {
        height: 300px;
      }
      iframe {
        border: none;
      }
    }
    .feedback {
      padding: 10px;
      box-sizing: border-box;
      background: #416090;

      .inner-feedback {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .feedback-title {
          font-size: 40px;
          font-weight: 700;
          color: white;
          text-align: center;

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
        .feedback-tags {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          font-size: 13px;

          span {
            // background-color: rgba(255, 255, 255, 0.1);
            color: white;
            text-align: center;
            font-size: 25px;
            padding: 3px 8px;
          }
        }
        .feedback-button {
          width: 100%;

          a {
            text-decoration: none;
          }

          // Styles for mobile devices
          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }
          .button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid white;
            font-family: "Afacad", sans-serif;
            color: white;
            padding: 5px 15px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // transition: all 300ms;
          }
          .arrow-right {
            fill: white;
            // transition: all 300ms;
          }

          :hover {
            background-color: white;
            color: #416090;
            .arrow-right {
              fill: #416090;
            }
          }
        }
      }
    }
    .quote {
      margin-bottom: 10px;
      // background: var(--gradient-bg);
      background: linear-gradient(300deg,#64ACB8,#3D799A,#4B397F);
      background-size: 200% 200%;
      animation: gradient-animation 18s ease infinite;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: white;
      box-sizing: border-box;
      padding: 0 20px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 20px;
      }

      @keyframes gradient-animation {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }

      .quote-left {
        // border: 1px solid red;
        display: flex;
        gap: 20px;
        align-items: center;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        .company {
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 767px) {
            span {
              text-align: center;
            }
          }
        }

        img {
          height: 35px;

          @media screen and (max-width: 767px) {
            height: 70px;
          }
        }
        span {
          font-size: 16px;
          font-weight: 700;

          @media screen and (max-width: 767px) {
            font-size: 20px;
          }
        }
      }
      .quote-right {
        // border: 1px solid yellow;
        text-align: end;

        @media screen and (max-width: 767px) {
          text-align: center;
        }

        span {
          font-size: 20px;
          font-weight: 700;

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }

    /* grid for mobile */
    @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(15, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .div1  { grid-area: 1 / 1 / 2 / 2; }   /* Position 1 */
    .div2  { grid-area: 2 / 1 / 3 / 2; }   /* Position 2 */
    .div11 { grid-area: 3 / 1 / 4 / 2; }   /* Moved to Position 3 */
    .div5  { grid-area: 4 / 1 / 5 / 2; }   /* Shifted to Position 4 */
    .div6  { grid-area: 5 / 1 / 6 / 2; }   /* Shifted to Position 5 */
    .div7  { grid-area: 6 / 1 / 7 / 2; }   /* Shifted to Position 6 */
    .div12 { grid-area: 7 / 1 / 8 / 2; }   /* Shifted to Position 7 */
    .div13 { grid-area: 8 / 1 / 9 / 2; }   /* Shifted to Position 8 */
    .div8  { grid-area: 9 / 1 / 10 / 2; }  /* Shifted to Position 9 */
    .div4  { grid-area: 10 / 1 / 11 / 2; } /* Shifted to Position 10 */
    .div10 { grid-area: 11 / 1 / 12 / 2; } /* Shifted to Position 11 */
    .div9  { grid-area: 12 / 1 / 13 / 2; } /* Shifted to Position 12 */
    .div14 { grid-area: 13 / 1 / 14 / 2; } /* Shifted to Position 13 */
    .div3  { grid-area: 14 / 1 / 15 / 2; } /* Shifted to Position 14 */
    .div15 { grid-area: 15 / 1 / 16 / 2; } /* Shifted to Position 15 */
  }
  
  }

  // grid for desktop
  .div1 { grid-area: 1 / 1 / 4 / 4; }
  .div2 { grid-area: 1 / 4 / 4 / 10; }
  .div3 { grid-area: 1 / 10 / 4 / 13; }
  .div4 { grid-area: 4 / 1 / 8 / 4; }
  .div5 { grid-area: 4 / 4 / 8 / 6; }
  .div6 { grid-area: 4 / 6 / 8 / 8; }
  .div7 { grid-area: 4 / 8 / 8 / 10; }
  .div8 { grid-area: 4 / 10 / 6 / 13; }
  .div9 { grid-area: 6 / 10 / 8 / 13; }
  .div10 { grid-area: 8 / 1 / 12 / 4; }
  .div11 { grid-area: 8 / 4 / 12 / 6; }
  .div12 { grid-area: 8 / 6 / 12 / 8; }
  .div13 { grid-area: 8 / 8 / 12 / 10; }
  .div14 { grid-area: 8 / 10 / 12 / 13; }
  .div15 { grid-area: 12 / 1 / 13 / 13; }
}
