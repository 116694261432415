#benefits {
  // border: 1px solid red;
  header {
    // border: 1px solid red;
    margin-top: 55px;
    height: 200px;
    overflow: hidden;
    position: relative;

    .banner-img {
      // border: 1px solid blue;
      position: relative;
      // height: 100%;

      img {
        position: absolute;
        top: 50%;
        width: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    .banner-overlay {
      position: absolute;
      background: linear-gradient(45deg, rgba(158, 189, 19, 0.75) , rgba(0, 133, 82, 0.75));
      height: 100%;
      width: 100%;
      padding: 24px;
      box-sizing: border-box;

      .banner-wrapper {
        // border: 1px solid white;
        max-width: var(--max-width);
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;

        .banner-title {
          font-size: 50px;
          font-weight: 700;
          text-align: center;
          @media screen and (max-width: 767px) {
            font-size: 30px;
            margin-bottom: 10px;
          }
        }
        .banner-subtitle {
          font-size: 25px;
          text-align: center;
          @media screen and (max-width: 767px) {
            line-height: 25px;
          }
        }
      }
    }
  }

  .benefits {
    .benefits-container {
      // border: 1px solid red;
      padding: 0 24px;
      max-width: var(--max-width);
      margin: 0 auto;
      .benefits-title {
        font-size: 40px;
        font-weight: 700;
        background-image: var(--gradient-45-deg);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;

        @media screen and (max-width: 767px) {
          line-height: 40px;
        }
      }
      .benefits-subtitle {
        display: block;
        color: var(--sub-color);
        margin-top: 10px;
      }

      .portal-container {
        // border: 1px solid blue;
        margin-top: 10px;
        margin-bottom: 60px;
        display: flex;
        flex-direction: row;
        // height: 500px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        .portal-img {
          // height: 100%;
          width: 50%;
          height: 100%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }

          img{
            width: 100%;
            // aspect-ratio: 1/1;
          }
        }
        .portal-article {
          padding: 20px;
          box-sizing: border-box;
          width: 50%;
          color: var(--sub-color);
          span {
            font-size: 25px;
            @media screen and (max-width: 767px) {
              font-size: 16px;
            }
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 20px 0;
          }

          .portal-title {
            font-size: 20px;
            font-weight: 700;
            color: var(--sub-color);
          }
        }
      }

      .retirement-container {
        // border: 1px solid blue;
        margin-top: 20px;
        margin-bottom: 60px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .each-retirement-card {
          // border: 1px solid green;
          // height: 250px;
          // width: 391px;
          flex: 1 1 calc(50% - 20px);

          /* Mobile View */
          @media screen and (max-width: 767px) {
            flex: 1 1 100%; /* 1 column */
          }

          .ret-img {
            // border: 1px solid red;
            height: 250px;
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              position: absolute;
              top: 50%;
              width: 100%;
              object-fit: cover;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
          }
          .ret-title {
            display: block;
            margin: 10px 0 5px 0;
            font-size: 20px;
            font-weight: 700;
            color: var(--sub-color);
          }
          .ret-desc {
            display: block;
            color: var(--sub-color);
            margin-bottom: 10px;

          }
        }
      }

      .partners-container {
        // border: 1px solid blue;
        margin-top: 60px;

        .partners-img-holder {
          // border: 1px solid red;
          height: 100px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 50px;
          img {
            height: 80px;
          }
        }
      }

      .proposal-container {
        // border: 1px solid red;
        max-width: 600px;
        margin: 0 auto;
        margin-top: 60px;

        .prop-title {
          // border: 1px solid green;
          display: flex;
          justify-content: center;
        }
        .prop-body {
          // border: 1px solid green;

          form {
            margin-top: 20px;
            .two-cols {
              // border: 1px solid blue;
              display: flex;
              gap: 10px;
              margin-bottom: 10px;

              @media screen and (max-width: 767px) {
                flex-direction: column;
              }

              .each-input {
                width: 100%;
              }
              .blank {
                width: 100%;
              }
              .send-form {
                width: 100%;
                height: 100%;
                background: transparent;
                border: 1px solid var(--main-color);
                font-family: "Afacad", sans-serif;
                color: var(--main-color);
                padding: 5px 0;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 20px;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                cursor: pointer;
                box-sizing: border-box;
                // transition: all 300ms;
                &:hover {
                  background-color: var(--main-color);
                  color: white;
                  .arrow-right {
                    fill: white;
                  }
                }
              }
              
            }
            .message {
              width: 100%;
              margin-bottom: 10px;

              .each-input {
                width: 100%;
              }
            }
            .alert {
              // border: 1px solid red;
              margin-bottom: 10px;
            }
          }

        }

        .proposal {
          font-size: 40px;
          font-weight: 700;
          background-image: var(--gradient-45-deg);
          background-size: 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          // color: var(--sub-color);
          text-align: center;
        }
      }
      
      
    }
  }
}