:root {
  --main-color: #089CAA;
  --sub-color: #484685;
  /* --main-color: #151515; */
  --main-bg: #F5F5F5;
  --gradient-text: linear-gradient(to right, #0072ff , #00c6ff);
  --gradient-45-deg: linear-gradient(45deg, #4B397F , #3D799A, #64ACB8);
  --gradient-bg: linear-gradient(to right, #4B397F , #3D799A, #64ACB8);
  --gradient-new: linear-gradient(to right, #68B7DA, #6894D9, #6771D9, #7F67D9, #A168D8, #C667D9);
  --subtitle-dark: #939ba1;
  --subtitle: #61676c;
  --max-width: 1200px;
  --max-width-docs: 600px;
  --footer-adjustment: calc(100vh - 298px);
  --footer-adjustment-404: calc(100vh - 328px);


  /* --max-width: 1480px; */

  /* --test1: 1px solid red;
  --test2: 1px solid green;
  --test3: 1px solid yellow;
  --test4: 1px solid purple; */
}

body {
  margin: 0;
  font-family: "Afacad", sans-serif;
  /* font-weight: 400; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* transition: all 300ms linear !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-link {
  text-decoration: none !important;
  color: var(--main-color);
}

.loader {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--main-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

#pagenotfound {
  height: var(--footer-adjustment-404);
  transition: all 300ms linear;
}
.pagenotfound {
  height: 100%;
}

.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.h-center {
  align-items: center;
}
.v-center {
  justify-content: center;
}

section {
  padding: 30px 0px;
  border: var(--test1);
  transition: all 300ms linear;
  position: relative;
  z-index: 10 !important;
}

.section-top {
  margin-top: 30px;
}
@media screen and (max-width: 480px) {
  .section-top {
    margin-top: 60px;
  }
}
.section-top-websites {
  margin-top: 30px;
}
@media screen and (max-width: 480px) {
  .section-top-websites {
    margin-top: 30px;
  }
}
.section-bottom {
  margin-bottom: 30px;
}

.section-footer {
  padding: 60px 0 !important;
}

section .title {
  /* font-family: 'Roboto'; */
  font-size: 80px;
  font-weight: 700;
  /* margin: 10px 0; */
  background-image: var(--gradient-45-deg);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}
@media screen and (max-width: 480px) {
  section .title {
    font-size: 50px;
  }
}
section .notfound {
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0;
  color: var(--subtitle);
  transition: all 300ms linear;
}
section .subtitle {
  font-size: 20px;
  font-weight: 400;
  margin: 5px 0;
  color: var(--main-color);
  /* transition: all 300ms linear !important; */
  width: 100px;
  /* border: 1px solid red; */
}

section .subtitle span {
  transition: all 300ms linear;
}

section .highlight {
  font-size: 30px;
  font-weight: 700;
  margin-right: 0;
  margin-bottom: 5px;
}
.section-info {
  line-height: 25px;
  margin-right: 0;
}

section .wrapper {
  display: flex;
  flex-direction: row;
  border: var(--test1);
  padding: 0 24px;
  max-width: var(--max-width);
  margin: 0 auto; 
  height: 100%;
}
section .wrapper-docs {
  display: flex;
  flex-direction: row;
  border: var(--test1);
  padding: 0 30px;
  max-width: var(--max-width-docs);
  margin: 0 auto; 
  height: 100%;
}

section .card-wrapper {
  border: var(--test1);
  padding: 0 24px;
  max-width: var(--max-width);
  margin: 0 auto; 
}

section .pagetopMargin {
  height: 20px;
  width: 100%;
  /* background-color: red; */
}
@media screen and (max-width: 480px) {
  section .pagetopMargin {
    display: none;
  }
}

.gradient-text {
  background-image: linear-gradient(45deg, #0379C3 , #63C1FC);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.menu-panel-none {
  visibility: hidden !important;
}

/* override icons thickness */
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 200,
  'GRAD' 0,
  'opsz' 24
}

.custom-tooltip {
  z-index: 6;
}

/* dynamic article cards style here */
#related-article{
  /* border: 1px solid red; */
  display: block;
  margin-bottom: 60px;
}
.related-articles-container{
  /* border: 1px solid red; */
  /* width: 50%; */
  margin: 0 auto;
}
@media screen and (max-width: 480px) {
  .related-articles-container{
    width: 100%;
  }
}
.related-articles{
  /* border: 1px solid blue; */
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  background-image: var(--gradient-45-deg);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}
.articles-container {
  /* border: 1px solid red; */
  /* width: 50%; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  padding-top: 30px;
  margin-bottom: 60px;
  box-sizing: border-box;
}
@media screen and (max-width: 480px) {
  .articles-container {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
.each-article {
  /* border: 1px solid blue; */
  /* height: 300px; */
  /* padding-bottom: 20px; */
  cursor: pointer;
  display: flex;
}
@media screen and (max-width: 480px) {
  .each-article {
    flex-direction: column;
  }
  .article-banner-container {
    width: 100% !important;
  }
  .article-contents {
    padding: 20px 20px 20px 0 !important;
  }


}
.article-banner-container {
  /* border: 1px solid green; */
  overflow: hidden;
  height: 150px;
  width: 300px;
  min-width: 300px;
  position: relative;
  /* margin-bottom: 5px; */
}
.article-contents {
  /* border: 1px solid pink; */
  box-sizing: border-box;
  padding: 20px;
}
.article-banner-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* @media screen and (max-width: 480px) {
  .article-banner-container img {
    width: 100%;
    height: auto;
  }
} */

.article-date {
  display: block;
  margin-bottom: 10px;
  color: var(--subtitle);
  font-size: 13px;
}
.article-author {
  display: block;
  margin-bottom: 10px;
  color: var(--subtitle);
}
.article-headline {
  display: block;
  margin: 10px 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  color: var(--sub-color);
}
.each-article:hover .article-headline,
.each-article:hover .article-author,
.each-article:hover .article-body {
  color: var(--main-color);
}

.show-all-container {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
.show-all {
  font-size: 20px;
  font-weight: 700;
  color: var(--main-color);
  cursor: pointer;
}
/* .show-all:hover {
  color: var(--main-color);
} */

.back-to-top-btn {
  /* border: 1px solid red !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}