#about {
    // border: 1px solid red;
    margin-top: 60px;
  
  
    .about {
      .about-container {
        // border: 1px solid red;
        padding: 0 24px;
        max-width: var(--max-width);
        margin: 0 auto;
        .about-title {
          font-size: 40px;
          font-weight: 700;
          background-image: var(--gradient-45-deg);
          background-size: 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          line-height: 40px;
  
          @media screen and (max-width: 767px) {
            line-height: 40px;
          }
        }
        .about-subtitle {
          display: block;
          color: var(--sub-color);
          // margin-top: 10px;
          font-size: 20px;
          line-height: 20px;
  
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        .leaders-container {
            background: var(--gradient-45-deg);
            // height: 300px;
            margin-top: 30px;
            padding: 20px 200px;
            display: flex;
            justify-content: space-around;

            @media screen and (max-width: 767px) {
              flex-direction: column;
              padding: 20px 0;
            }

            .each-leader {
                // border: 1px solid white;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
                .img-cont {
                    img {
                        height: 100px;
                    }
                }
                span {
                    color: white;
                }
                .name {
                    font-weight: 700;
                    font-size: 20px;
                    margin-top: 10px;
                }
            }
        }

        .team-photo {
          height: auto;
          width: 100%;
          margin-bottom: 60px;
        }

        .values-container {
          // border: 1px solid blue;
          margin-top: 30px;
          margin-bottom: 60px;
          display: flex;
          gap: 10px;
          width: 100%;

          @media screen and (max-width: 767px) {
            flex-direction: column;
          }

          .value1 {
            background: rgba(70, 79, 136, 0.2);
            span {
              color: #484685;
            }
          }
          .value2 {
            background: rgba(65, 105, 147, 0.2);
            span {
              color: #416893;
            }
          }
          .value3{
            background: rgba(66, 127, 158, 0.2);
            span {
              color: #42809E;
            }
          }
          .value4 {
            background: rgba(86, 153, 173, 0.15);
            span {
              color: #5699AD;
            }
          }

          .each-value {
          // border: 1px solid green;
          display: flex;
          flex-direction: column;
          width: 100%;

          .value-img {
            height: 175px;
            position: relative;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              position: absolute;
              object-fit: cover;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
          }
          .value-title {
            margin-top: 20px;
            text-align: center;
            width: 100%;
            font-size: 30px;
            font-weight: 700;
          }
          .value-content {
            display: block;
            margin: 20px;
          }
          }
        }

        .related-articles-container {
          margin-top: 60px;
        }
        .leader-story-container {
          // border: 1px solid red;
          margin-top: 30px;
          margin-bottom: 60px;
          // height: 300px;
          display: flex;
          flex-direction: row;
          gap: 10px;

          @media screen and (max-width: 767px) {
            flex-direction: column;
          }

          .each-leader-story {
            // border: 1px solid green;
            height: 300px;
            width: 100%;
            position: relative;
            background: var(--main-color);
            cursor: pointer !important;

            img {
              width: 100%;
              height: 100%;
              position: absolute;
              object-fit: cover;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }

            .story-container{
              // border: 1px solid red;
              // background: rgba(0, 0, 0, 0.21);
              // background: linear-gradient(to top, rgba(255, 255, 255, 0.526) , transparent);
              padding: 0 30px 10px 30px;
              position: absolute;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              flex-direction: column;
              box-sizing: border-box;
              width: 100%;
              transition: all 300ms;
              cursor: pointer;
              pointer-events: auto;
              // -webkit-backdrop-filter: blur(10px);
              // backdrop-filter: blur(10px);
              &:hover {
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                background: rgba(0, 0, 0, 0.21);
                cursor: pointer;
                pointer-events: auto;


                .story-content, .story-read-more {
                  display: block;
                  cursor: pointer;
                  pointer-events: auto;

                }
              }

              @media screen and (max-width: 767px) {
                width: 100%;
              }

              span {
                color: white;
                text-shadow: 2px 3px 3px rgba(0,0,0,0.3);
              }
              .story-title {
                font-size: 45px;
                font-weight: 700;
              }
              .story-founder {
                // text-shadow: 2px 3px 3px rgba(0,0,0,0.3);
              }
              .story-author {
                font-weight: 700;
                // margin-bottom: 20px;
              }
              .story-content {
                text-align: end;
                margin-top: 20px;
                display: none;
                transition: all 300ms;

                // text-shadow: 2px 3px 3px rgba(0,0,0,0.3);

              }
              .story-read-more {
                font-weight: 700;
                margin-top: 20px;
                cursor: pointer;
                pointer-events: auto;
                display: block;
                transition: all 300ms;

              }
            }
          }
        }

        .founder-article {
          // border: 1px solid blue;
          width: 75%;
          margin: 0 auto;
          margin-bottom: 60px;
          font-size: 18px;
    
          @media screen and (max-width: 767px) {
            width: 100%;
          }
    
          .back-button {
            // border: 1px solid red;
            width: 100px;
            margin-bottom: 30px;
    
            @media screen and (max-width: 767px) {
              // margin-bottom: 30px !important;
            }
    
    
            .button {
              width: 100%;
              height: 100%;
              background: transparent;
              border: none;
              font-family: "Afacad", sans-serif;
              color: var(--main-color);
              padding: 0;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 20px;
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row-reverse;
              cursor: pointer;
              // transition: all 300ms;
    
              .back-arrow {
                transform: rotate(180deg);
              }
            }
            .arrow-right {
              fill: var(--main-color);
              // transform: rotate(180deg);
              // transition: all 300ms;
    
            }
    
            // :hover {
            //   // background-color: var(--main-color);
            //   color: var(--sub-color);
            //   .arrow-right {
            //     fill: var(--sub-color);
            //   }
            // }
          }
    
    
          .founder-article-banner-container {
            // border: 1px solid green;
            // overflow: hidden;
            // height: 200px;
            // height: 100%;
            position: relative;
            margin-bottom: 20px;
            // margin-top: 20px;
    
            @media screen and (max-width: 767px) {
              margin-top: 30px;
            }
    
            img {
              width: 100%;
              // height: 100%;
              // width: 100%;
              // object-fit: cover;
              // position: absolute;
              // top: 50%;
              // left: 50%;
              // -ms-transform: translate(-50%, -50%);
              // transform: translate(-50%, -50%);
            }
    
          }
    
    
          .reader-article-headline {
            color: var(--sub-color);
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
          }
    
          .article-author {
            display: block;
            color: var(--subtitle);
            // margin-top: 30px;
          }
    
          .article-body {
            // border: 1px solid red;
            margin-top: 30px;
    
            .article-body-row {
            // border: 1px solid blue;
            display: flex;
            align-items: row;
            justify-content: center;
            align-items: center;
            width: 100%;
    
            @media screen and (max-width: 767px) {
              flex-direction: column;
            }
    
            .article-body-image-container {
              // border: 1px solid green;
              // object-fit: cover;
              // width: 100%;
              // height: 100%;
              // width: 500px;
              // flex: 100%;
              img {
                // width: 100%;
                // height: 100%;
                // aspect-ratio: auto;
              }
    
            }
            img {
              // border: 1px solid green;
              height: auto;
              aspect-ratio: auto;
              width: 100%;
              @media screen and (max-width: 767px) {
                height: auto;
              }
            }
            .article-body-p-container-right {
              // border: 1px solid green;
    
              padding: 20px 0 20px 20px;
              display: flex;
              align-items: center;
              flex-direction: column;
              @media screen and (max-width: 767px) {
                padding: 0;
                // padding: 0 20px 0 0;
              }
            }
            .article-body-p-container-left {
              padding: 20px 20px 20px 0;
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                padding: 0;
                // padding: 0 0 20px 0;
                // margin-top: 20px;
              }
            }
    
            }
            .article-body-col {
            // border: 1px solid blue;
            display: flex;
            align-items: row;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
    
            @media screen and (max-width: 767px) {
              flex-direction: column;
            }
    
            .article-body-image-container {
              // border: 1px solid green;
              // object-fit: cover;
              // width: 100%;
              // height: 100%;
              // width: 500px;
              // flex: 100%;
              img {
                // width: 100%;
                // height: 100%;
                // aspect-ratio: auto;
              }
    
            }
            img {
              // border: 1px solid green;
              // height: 300px;
              aspect-ratio: auto;
              width: 100%;
              @media screen and (max-width: 767px) {
                height: auto;
              }
            }
            .article-body-p-container-right {
              // border: 1px solid green;
    
              padding: 20px 0 20px 20px;
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                padding: 20px 0;
              }
            }
            .article-body-p-container-left {
              // padding: 20px 20px 20px 0;
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                // padding: 0 0 20px 0;
              }
            }
    
            }
          }
    
    
        }

        .hr {
          width: 100%;
          border-top: 0.5px solid #8f8f8f;
          margin-bottom: 60px;
        }

        .fit-article {
          // border: 1px solid blue;
          width: 75%;
          margin: 0 auto;
          margin-bottom: 60px;
          font-size: 18px;
    
          @media screen and (max-width: 767px) {
            width: 100%;
          }
    
          .back-button {
            // border: 1px solid red;
            width: 100px;
            margin-bottom: 30px;
    
            @media screen and (max-width: 767px) {
              // margin-bottom: 30px !important;
            }
    
    
            .button {
              width: 100%;
              height: 100%;
              background: transparent;
              border: none;
              font-family: "Afacad", sans-serif;
              color: var(--main-color);
              padding: 0;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 20px;
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row-reverse;
              cursor: pointer;
              // transition: all 300ms;
    
              .back-arrow {
                transform: rotate(180deg);
              }
            }
            .arrow-right {
              fill: var(--main-color);
              // transform: rotate(180deg);
              // transition: all 300ms;
    
            }
    
            // :hover {
            //   // background-color: var(--main-color);
            //   color: var(--sub-color);
            //   .arrow-right {
            //     fill: var(--sub-color);
            //   }
            // }
          }
    
    
          .fit-article-banner-container {
            // border: 1px solid green;
            // overflow: hidden;
            // height: 200px;
            // height: 100%;
            position: relative;
            margin-bottom: 20px;
            // margin-top: 20px;
    
            @media screen and (max-width: 767px) {
              margin-top: 30px;
            }
    
            img {
              width: 100%;
              // height: 100%;
              // width: 100%;
              // object-fit: cover;
              // position: absolute;
              // top: 50%;
              // left: 50%;
              // -ms-transform: translate(-50%, -50%);
              // transform: translate(-50%, -50%);
            }
    
          }
    
    
          .reader-article-headline {
            color: var(--sub-color);
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
          }
    
          .article-author {
            display: block;
            color: var(--subtitle);
            // margin-top: 30px;
          }
    
          .article-body {
            // border: 1px solid red;
            // margin-top: 30px;
    
            .article-body-row {
            // border: 1px solid blue;
            display: flex;
            align-items: row;
            justify-content: center;
            align-items: center;
            width: 100%;
    
            @media screen and (max-width: 767px) {
              flex-direction: column;
            }
    
            .article-body-image-container {
              // border: 1px solid green;
              // object-fit: cover;
              // width: 100%;
              // height: 100%;
              // width: 500px;
              // flex: 100%;
              img {
                // width: 100%;
                // height: 100%;
                // aspect-ratio: auto;
              }
    
            }
            img {
              // border: 1px solid green;
              height: 300px;
              aspect-ratio: auto;
              width: 100%;
              @media screen and (max-width: 767px) {
                height: auto;
              }
            }
            .article-body-p-container-right {
              // border: 1px solid green;
    
              padding: 20px 0 20px 20px;
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                padding: 0;
              }
            }
            .article-body-p-container-left {
              padding: 20px 20px 20px 0;
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                padding: 0;
                // padding: 0 0 20px 0;
                // margin-top: 20px;
              }
            }
    
            }
            .article-body-col {
            // border: 1px solid blue;
            display: flex;
            align-items: row;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;
    
            @media screen and (max-width: 767px) {
              flex-direction: column;
              margin-bottom: 0;
            }
    
            .article-body-image-container {
              // border: 1px solid green;
              // object-fit: cover;
              // width: 100%;
              // height: 100%;
              // width: 500px;
              // flex: 100%;
              img {
                // width: 100%;
                // height: 100%;
                // aspect-ratio: auto;
              }
    
            }
            img {
              // border: 1px solid green;
              // height: 300px;
              aspect-ratio: auto;
              width: 100%;
              @media screen and (max-width: 767px) {
                height: auto;
              }
            }
            .article-body-p-container-right {
              // border: 1px solid green;
    
              padding: 20px 0 20px 20px;
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                padding: 20px 0;
              }
            }
            .article-body-p-container-left {
              // padding: 20px 20px 20px 0;
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                padding: 0 0 20px 0;
              }
            }
    
            }
          }
    
    
        }
      }
    }
  }