#insurance {
  // border: 1px solid red;
  header {
    // border: 1px solid red;
    margin-top: 55px;
    height: 200px;
    overflow: hidden;
    position: relative;

    .banner-img {
      // border: 1px solid blue;
      position: relative;
      // height: 100%;

      img {
        position: absolute;
        top: 50%;
        width: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    .banner-overlay {
      position: absolute;
      background: linear-gradient(45deg, rgba(0, 208, 255, 0.75) , rgba(58, 71, 213, 0.75));
      height: 100%;
      width: 100%;
      padding: 24px;
      box-sizing: border-box;

      .banner-wrapper {
        // border: 1px solid white;
        max-width: var(--max-width);
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;

        .banner-title {
          font-size: 50px;
          font-weight: 700;
          text-align: center;
          @media screen and (max-width: 767px) {
            font-size: 30px;
            margin-bottom: 10px;
          }
        }
        .banner-subtitle {
          font-size: 25px;
          text-align: center;
          @media screen and (max-width: 767px) {
            line-height: 25px;
          }
        }
      }
    }
  }

  .insurance {
    .insurance-container {
      // border: 1px solid red;
      padding: 0 24px;
      max-width: var(--max-width);
      margin: 0 auto;
      .insurance-title {
        font-size: 50px;
        font-weight: 700;
        background-image: var(--gradient-45-deg);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
        line-height: 50px;

        @media screen and (max-width: 767px) {
          font-size: 40px;
          line-height: 40px;
        }
      }
      .insurance-subtitle {
        display: block;
        color: var(--sub-color);
        margin-top: 10px;
      }

      .e2e-container {
      // border: 1px solid blue;
      margin: 30px 0;
      display: flex;
      flex-direction: row;
      // justify-content: space-around;
      gap: 10px;
      // flex-wrap: wrap;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      .each-e2e {
        box-sizing: border-box;
        padding: 20px 30px;
        background: gray;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        span {
          color: white;
          font-size: 20px;
        }
      }
      .life {
        background: #474B87;
      }
      .non-life {
        background: #435C8E;
      }
      .hmo {
        background: #3F6F96;
      }
      .pre-need {
        background: #417F9D;
      }
      .social {
        background: #4E8FA7;
      }

      }

      .e2e-list-container {
        // border: 1px solid blue;
        margin-top: 60px;
        // margin-bottom: 60px;

        .e2e-row {
          // border: 1px solid green;
          display: flex;
          flex-direction: row;
          // gap: 10px;

          @media screen and (max-width: 767px) {
            flex-direction: column;
          }

          .each-in-row{
            // border: 1px solid pink;
            display: flex;
            flex-direction: column;
            width: 100%;

            .adj-bar {
              // border: 1px solid orange;
              display: flex;
              height: fit-content;
              gap: 20px;
              margin-bottom: 20px;
            }

            .bar {
              margin-right: 20px;
              width: 5px;
              background: linear-gradient(to bottom, #4B397F , #3D799A, #64ACB8);;
            }
            .content {
              .e2e-title {
                // padding: 25px;
                font-size: 30px;
                line-height: 30px;
                font-weight: 700;
                color: var(--sub-color);
              }
              .e2e-list {
  
              }
            }
          }

          
        }
      }

      .proposal-container {
        // border: 1px solid red;
        max-width: 600px;
        margin: 0 auto;

        .prop-title {
          // border: 1px solid green;
          display: flex;
          justify-content: center;
        }
        .prop-body {
          // border: 1px solid green;

          form {
            margin-top: 20px;
            .two-cols {
              // border: 1px solid blue;
              display: flex;
              gap: 10px;
              margin-bottom: 10px;

              @media screen and (max-width: 767px) {
                flex-direction: column;
              }

              .each-input {
                width: 100%;
              }
              .blank {
                width: 100%;
              }
              .send-form {
                width: 100%;
                height: 100%;
                background: transparent;
                border: 1px solid var(--main-color);
                font-family: "Afacad", sans-serif;
                color: var(--main-color);
                padding: 5px 0;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 20px;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                cursor: pointer;
                box-sizing: border-box;
                // transition: all 300ms;
                &:hover {
                  background-color: var(--main-color);
                  color: white;
                  .arrow-right {
                    fill: white;
                  }
                }
              }
              
            }
            .message {
              width: 100%;
              margin-bottom: 10px;

              .each-input {
                width: 100%;
              }
            }
            .alert {
              // border: 1px solid red;
              margin-bottom: 10px;
            }
          }

        }

        .proposal {
          margin-top: 60px;
          font-size: 40px;
          font-weight: 700;
          background-image: var(--gradient-45-deg);
          background-size: 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          // color: var(--sub-color);
          text-align: center;
        }
      }
      
    }
  }
}