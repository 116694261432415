#privacy {
  .wrapper
  .privacy-content {
    // border: 1px solid red;
    width: 50%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    strong {
      transition: all 300ms linear;
    }
  }
  ul {
    margin: 0;
    padding-left: 20px;
    li {
      margin-bottom: 10px;
    }
  }
}

.privacy-title {
  font-size: 40px;
  font-weight: 700;
  background-image: var(--gradient-45-deg);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}