#pension {
  // border: 1px solid red;
  header {
    // border: 1px solid red;
    margin-top: 55px;
    height: 200px;
    overflow: hidden;
    position: relative;

    .banner-img {
      // border: 1px solid blue;
      position: relative;
      // height: 100%;

      img {
        position: absolute;
        top: 50%;
        width: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    .banner-overlay {
      position: absolute;
      background: linear-gradient(45deg, rgba(252, 70, 106, 0.75) , rgba(63, 94, 251, 0.75));
      height: 100%;
      width: 100%;
      padding: 24px;
      box-sizing: border-box;

      .banner-wrapper {
        // border: 1px solid white;
        max-width: var(--max-width);
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;

        .banner-title {
          font-size: 50px;
          font-weight: 700;
          text-align: center;
          @media screen and (max-width: 767px) {
            font-size: 30px;
            margin-bottom: 10px;
          }
        }
        .banner-subtitle {
          font-size: 25px;
          text-align: center;
          @media screen and (max-width: 767px) {
            line-height: 25px;
          }
        }
      }
    }
  }

  .pension {
    // margin-top: 30px;
    .pension-container {
      padding: 0 24px;
      max-width: var(--max-width);
      margin: 0 auto;
      .services {
        font-size: 40px;
        font-weight: 700;
        color: var(--main-color);
        // background-image: var(--gradient-45-deg);
        // background-size: 100%;
        // background-clip: text;
        // -webkit-background-clip: text;
        // -moz-background-clip: text;
        // -webkit-text-fill-color: transparent; 
        // -moz-text-fill-color: transparent;
      }

      article {
        width: 100%;
        // border: 1px solid blue;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;

        p {
          // border: 1px solid red;
          text-align: center;
          font-size: 50px;
          line-height: 50px;
          color: var(--main-color);
          font-weight: 700;

          span {
            color: var(--sub-color);
          }
          // width: 50%;
          @media screen and (max-width: 767px) {
            font-size: 30px;
            line-height: 30px;
          }
        
        }
        .this-exp {
          margin-top: -15px;
          margin-bottom: 60px;
        }
      }

      .services-cards {
        // border: 1px solid red;
        display: flex;
        width: 100%;
        gap: 10px;
        margin: 10px 0 60px 0;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        .apv {
          background: linear-gradient(to right, #493F81 , #435C8D);
        }
        .eba {
          background: linear-gradient(to right, #42608F , #407D9C);
        }
        .imc {
          background: linear-gradient(to right, #42809E , #579CAE);
        }

        .each-service-card {
          color: white;
          padding: 30px 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;


          .service-title {
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 10px;
            font-weight: 700;
            text-align: center;
          }
          .service-info {
            display: inline-flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 20px;

            .pension {
              color: #493F81;
            }
            .funding {
              color: #42608F;
            }
            .special {
              color: #42809E;
            }

            .service-chip {
              // border: 1px solid yellow;
              display: flex;
              align-items: center;
              justify-content: center;
              // width: 100%;
            }
            span {
              width: fit-content;
              text-align: center;
              display: block;
              // background: white;
              color: white;
              border-radius: 3px;
              // padding: 3px 10px;
              font-size: 20px;
              @media screen and (max-width: 767px) {
                text-align: center;
              }
            }
          }
        }
      }

      .proposal-container {
        // border: 1px solid red;
        max-width: 600px;
        margin: 0 auto;

        .prop-title {
          // border: 1px solid green;
          display: flex;
          justify-content: center;
        }
        .prop-body {
          // border: 1px solid green;

          form {
            margin-top: 20px;
            .two-cols {
              // border: 1px solid blue;
              display: flex;
              gap: 10px;
              margin-bottom: 10px;

              @media screen and (max-width: 767px) {
                flex-direction: column;
              }

              .each-input {
                width: 100%;
              }
              .blank {
                width: 100%;
              }
              .send-form {
                width: 100%;
                height: 100%;
                background: transparent;
                border: 1px solid var(--main-color);
                font-family: "Afacad", sans-serif;
                color: var(--main-color);
                padding: 5px 0;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 20px;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                cursor: pointer;
                box-sizing: border-box;
                // transition: all 300ms;
                &:hover {
                  background-color: var(--main-color);
                  color: white;
                  .arrow-right {
                    fill: white;
                  }
                }
              }
              
            }
            .message {
              width: 100%;
              margin-bottom: 10px;

              .each-input {
                width: 100%;
              }
            }
            .alert {
              // border: 1px solid red;
              margin-bottom: 10px;
            }
          }

        }

        .proposal {
          font-size: 40px;
          font-weight: 700;
          // background-image: var(--gradient-45-deg);
          // background-size: 100%;
          // background-clip: text;
          // -webkit-background-clip: text;
          // -moz-background-clip: text;
          // -webkit-text-fill-color: transparent; 
          // -moz-text-fill-color: transparent;
          color: var(--sub-color);
          text-align: center;
        }
      }

      .contact-links {
        margin-top: 60px;
        // margin-bottom: 60px;
        // border: 1px solid red;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        gap: 50px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          gap: 20px;
        }

        a {
          text-decoration: none;
        }

        .each-contact-link {
        // border: 1px solid blue;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 767px) {
          justify-content: center;
        }

        span {
          color: var(--sub-color);
          font-size: 20px;
        }

          .call {
            background: #484785;
          }
          .email {
            background: #416893;
          }
          .fb {
            background: #42809E;
          }
          .icon-logo {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
        }
      }
    }
  }
}